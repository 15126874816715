'use client';

import { createContext, useContext, useMemo, useState } from 'react';

type HomeHeroContextType = {
  isBackgroundImageLoaded: boolean;
  setIsBackgroundImageLoaded: (isLoaded: boolean) => void;
};

const defaultState: HomeHeroContextType = {
  isBackgroundImageLoaded: false,
  setIsBackgroundImageLoaded: () => {},
};

export const HomeHeroContext = createContext<HomeHeroContextType>(defaultState);

export const HomeHeroContextProvider = ({
  children,
}: {
  children: React.ReactNode;
}) => {
  const [isBackgroundImageLoaded, setIsBackgroundImageLoaded] = useState(false);

  const contextValue = useMemo(
    () => ({
      isBackgroundImageLoaded,
      setIsBackgroundImageLoaded,
    }),
    [isBackgroundImageLoaded],
  );

  return (
    <HomeHeroContext.Provider value={contextValue}>
      {children}
    </HomeHeroContext.Provider>
  );
};

export const useHomeHeroContext = () => useContext(HomeHeroContext);
