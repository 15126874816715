import { cn } from '@/lib/tailwind';

import { GridWrapper } from '../../paginatedGrid/components/GridWrapper';
import { NewsroomFilterableGridProps } from '../types';
import { calculateLastRowStates } from '../../paginatedGrid/components/utils';

type Props = Pick<NewsroomFilterableGridProps, 'newsItems'>;

const Grid = ({ newsItems }: Pick<Props, 'newsItems'>) => {
  return (
    <div
      className={cn(
        'grid grid-cols-1 tablet-sm:grid-cols-2 desktop-sm:grid-cols-3',
      )}
    >
      {newsItems.map((item, index) => {
        const lastRowStates = calculateLastRowStates(newsItems.length, index);

        return (
          <GridWrapper key={index} {...lastRowStates}>
            {item.node}
          </GridWrapper>
        );
      })}
    </div>
  );
};

export default Grid;
