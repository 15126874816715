'use client';
import { ComponentPropsWithoutRef, Fragment, ReactNode, useState } from 'react';
import Icon from '@/external/icon';
import { cn } from '@/lib/tailwind';
import {
  HoverCard,
  HoverCardContent,
  HoverCardPortal,
  HoverCardTrigger,
} from '@/components/ui/hover-card';
import {
  Dialog,
  DialogClose,
  DialogContent,
  DialogTrigger,
} from '@/components/ui/dialog';

export const Hoverable = ({
  content,
  children,
  containerClassName,
  triggerClassName,
  contentClassname,
  side,
  align = 'start',
  sideOffset,
  avoidCollisions = true,
}: {
  content: string;
  children: ReactNode;
  side: 'left' | 'right' | 'bottom';
  align?: ComponentPropsWithoutRef<typeof HoverCardContent>['align'];
  sideOffset?: ComponentPropsWithoutRef<typeof HoverCardContent>['sideOffset'];
  avoidCollisions?: ComponentPropsWithoutRef<
    typeof HoverCardContent
  >['avoidCollisions'];
  containerClassName?: string;
  triggerClassName?: string;
  contentClassname?: string;
}) => {
  const [isHoverCardOpen, setIsHoverCardOpen] = useState(false);
  const [isDialogOpen, setIsDialogOpen] = useState(false);
  return (
    <Fragment>
      <HoverCard
        open={isHoverCardOpen}
        onOpenChange={setIsHoverCardOpen}
        openDelay={0}
        closeDelay={0}
      >
        <div
          className={cn(
            'relative max-tablet-sm:hidden',
            isHoverCardOpen && 'hover-active',
            containerClassName,
          )}
        >
          <HoverCardTrigger className="cursor-pointer">
            {children}
            <div
              className={cn(
                'bg-[#13161A] w-6 desktop-lg:w-8 aspect-square rounded-full border-white border border-dashed cursor-pointer',
                triggerClassName,
              )}
            >
              <span
                className={cn(
                  'w-3.5 desktop-lg:w-5',
                  'bg-white text-[#585F66] block rounded-full aspect-square',
                  'absolute inset-1/2 -translate-y-1/2 -translate-x-1/2',
                )}
              />
              <Icon
                icon={isHoverCardOpen ? 'minusSlim' : 'addSlim'}
                className={cn(
                  'w-2 desktop-lg:w-3',
                  'fill-[#585F66] aspect-square absolute inset-1/2 -translate-y-1/2 -translate-x-1/2',
                )}
              />
            </div>
          </HoverCardTrigger>
        </div>
        <HoverCardPortal>
          <HoverCardContent
            side={side}
            align={align}
            sideOffset={sideOffset}
            avoidCollisions={avoidCollisions}
            className={cn(
              'bg-white p-4 text-cometBlack rounded-[8px] border-lightGrey border z-50 max-tablet-sm:hidden',
              contentClassname,
            )}
          >
            {content}
          </HoverCardContent>
        </HoverCardPortal>
      </HoverCard>
      <Dialog open={isDialogOpen} onOpenChange={setIsDialogOpen} modal>
        <div
          className={cn(
            'relative tablet-sm:hidden',
            isDialogOpen && 'hover-active',
            containerClassName,
          )}
        >
          <DialogTrigger className="contents">
            {children}
            <div
              className={cn(
                'bg-[#13161A] w-6 desktop-lg:w-8 aspect-square rounded-full border-white border border-dashed cursor-pointer',
                triggerClassName,
              )}
            >
              <span
                className={cn(
                  'w-3.5 desktop-lg:w-5',
                  'bg-white text-[#585F66] block rounded-full aspect-square',
                  'absolute inset-1/2 -translate-y-1/2 -translate-x-1/2',
                )}
              />
              <Icon
                icon={isDialogOpen ? 'minusSlim' : 'addSlim'}
                className={cn(
                  'w-2 desktop-lg:w-3',
                  'fill-[#585F66] aspect-square absolute inset-1/2 -translate-y-1/2 -translate-x-1/2',
                )}
              />
            </div>
          </DialogTrigger>
        </div>
        <DialogContent className="tablet-sm:hidden bg-transparent border-none w-[312px]">
          <DialogClose className="ms-auto block" />
          <div className="bg-white p-4 text-cometBlack rounded-[8px] border-lightGrey border z-50">
            {content}
          </div>
        </DialogContent>
      </Dialog>
    </Fragment>
  );
};
