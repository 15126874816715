import Icon from '@/external/icon';

const PlayIcon = ({ isOverlay }: { isOverlay: boolean }) => {
  return isOverlay ? (
    <div className="w-full h-full flex items-center justify-center bg-black/20">
      <Icon
        icon="playRounded"
        className="aspect-square w-12 desktop-sm:w-[70px] desktop-lg:w-20"
      />
    </div>
  ) : (
    <Icon icon="videoPlayer" />
  );
};

export default PlayIcon;
