const generatePaginationRange = (
  noOfPages: number,
  currentPage: number,
  maxPagesToShow: number,
) => {
  if (noOfPages <= maxPagesToShow) {
    return Array.from({ length: noOfPages }, (_, i) => i + 1);
  }
  if (currentPage <= Math.ceil(maxPagesToShow / 2)) {
    return Array.from({ length: maxPagesToShow }, (_, i) => i + 1);
  }
  if (currentPage >= noOfPages - Math.floor(maxPagesToShow / 2)) {
    return Array.from(
      { length: maxPagesToShow },
      (_, i) => noOfPages - (maxPagesToShow - 1) + i,
    );
  }
  return Array.from(
    { length: maxPagesToShow },
    (_, i) => currentPage - Math.floor(maxPagesToShow / 2) + i,
  );
};

export default generatePaginationRange;
