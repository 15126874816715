import React, { useCallback, useContext, useLayoutEffect } from 'react';
import { usePagination } from 'react-instantsearch';
import { usePathname, useRouter, useSearchParams } from 'next/navigation';
import {
  Pagination,
  PaginationContent,
  PaginationNext,
  PaginationPrevious,
} from '@/components/ui/pagination';

import HeaderHeightContext from '@/contexts/HeaderHeightContext';
import scrollToElement from '@/utils/scrollToElement';
import { PaginationButton } from '../PaginationButton';
import { Props } from './types';

const PAGE_QUERY_PARAM = 'page';

const PaginationWrapper = ({
  className,
  gridContainerId,
  paginationScrollOffset,
  preserveStateInQueryParams = false,
  pagePadding = 1,
}: Props) => {
  const [headerHeight] = useContext(HeaderHeightContext);
  const router = useRouter();
  const pathname = usePathname();
  const searchParams = useSearchParams();

  const { pages, currentRefinement, isFirstPage, isLastPage, refine } =
    usePagination({
      padding: pagePadding,
    });

  const previousPageIndex = currentRefinement - 1;
  const nextPageIndex = currentRefinement + 1;

  useLayoutEffect(() => {
    if (preserveStateInQueryParams) {
      const initialPage = Number(searchParams.get(PAGE_QUERY_PARAM));
      if (initialPage > 1) {
        refine(initialPage - 1);
      }
    }
  }, [refine, searchParams, preserveStateInQueryParams]);

  const handlePaginationButtonClick = useCallback(
    (value: number) => {
      refine(value);
      if (preserveStateInQueryParams) {
        router.replace(
          value > 0 ? `?${PAGE_QUERY_PARAM}=${value + 1}` : pathname,
          {
            scroll: false,
          },
        );
      }
      scrollToElement(gridContainerId, headerHeight, paginationScrollOffset);
    },
    [
      refine,
      gridContainerId,
      headerHeight,
      paginationScrollOffset,
      router,
      pathname,
      preserveStateInQueryParams,
    ],
  );

  return pages.length > 1 ? (
    <Pagination className={className}>
      <PaginationContent>
        <PaginationButton
          value={previousPageIndex}
          variant={isFirstPage ? 'disabled' : 'highlighted'}
          disabled={isFirstPage}
          onClick={handlePaginationButtonClick}
        >
          <PaginationPrevious />
        </PaginationButton>

        {pages.map(page => (
          <PaginationButton
            key={page}
            value={page}
            onClick={handlePaginationButtonClick}
            variant={page === currentRefinement ? 'active' : 'default'}
          >
            <span
              key={page}
              className="text-mobile-sm/body-3 tablet-sm:text-tablet-sm/body-1 desktop-lg:text-desktop-large/body-1"
            >
              {page + 1}
            </span>
          </PaginationButton>
        ))}

        <PaginationButton
          value={nextPageIndex}
          variant={isLastPage ? 'disabled' : 'highlighted'}
          disabled={isLastPage}
          onClick={handlePaginationButtonClick}
        >
          <PaginationNext />
        </PaginationButton>
      </PaginationContent>
    </Pagination>
  ) : null;
};
export default PaginationWrapper;
