import { useTranslations } from 'next-intl';
import Icon from '@/external/icon';
import { cn } from '@/lib/tailwind';

const TITLE = 'FILTER';

export const FilterTitle = () => {
  const t = useTranslations();
  return (
    <p className="h-fit self-center">
      <Icon
        icon="funnel"
        className="w-3.5 tablet-sm:w-[18px] aspect-square inline fill-black flex-none"
      />
      <span
        className={cn(
          'inline-block align-middle ms-1 tablet-sm:ms-4 desktop-sm:ms-2',
          'text-mobile-sm/overline-2 tablet-sm:text-tablet-sm/overline-2 desktop-lg:text-desktop-large/overline-2',
        )}
      >
        {t(TITLE)}
      </span>
    </p>
  );
};
