'use client';
import {
  useClearRefinements,
  useCurrentRefinements,
} from 'react-instantsearch';
import { useCallback } from 'react';
import { useTranslations } from 'next-intl';
import Icon from '@/external/icon';
import { cn } from '@/lib/tailwind';
import { CLEAR_ALL_LABEL } from '../constants';

export const FilterClearButton = () => {
  const { refine } = useClearRefinements();
  const handleClick = useCallback(() => refine(), [refine]);
  const { items } = useCurrentRefinements();
  const t = useTranslations();

  return (
    <button
      className={cn(
        'w-fit justify-self-end desktop-sm:justify-self-start desktop-sm:order-last',
        'bg-white text-black border border-lightGrey fill-lightGrey',
        'hover:border-primaryOceanBlue hover:text-primaryOceanBlue',
        'uppercase rounded-full',
        'text-mobile-sm/overline-2 tablet-sm:text-tablet-sm/overline-4 desktop-lg:text-desktop-large/overline-3',
        'py-1.5 desktop-lg:py-1.5 px-4',
        'flex items-center',
        items.length === 0 && 'opacity-0 pointer-events-none',
      )}
      onClick={handleClick}
    >
      <span className="block">{t(CLEAR_ALL_LABEL)}</span>
      <Icon
        icon="closeCross"
        className={'aspect-square ms-2 w-3.5 tablet-sm:w-4'}
      />
    </button>
  );
};
