'use client';
import { useCallback, useRef, useState } from 'react';
import { useClickAway } from 'react-use';
import { cn } from '@/lib/tailwind';
import SubNavItem from '@/components/subNav/SubNavItem';
import { FilterItem, FilterItemProps } from '../types';

const MenuItem = ({ option, handleClick, selectedOption }: FilterItemProps) => {
  return (
    <div
      key={option.id}
      onClick={() => handleClick(option.id)}
      className={cn(
        'cursor-pointer block text-lightGrey hover:text-primaryOceanBlue transition-colors uppercase text-mobile-sm/label-2',
        'tablet-sm:text-tablet-sm/overline-3 desktop-lg:text-desktop-large/overline-3',
        selectedOption === option.label && 'text-primaryOceanBlue',
      )}
    >
      {option.label}
    </div>
  );
};

const Menu = ({ options, selectedOption, handleClick }: FilterItem) => {
  const [isMenuOpen, setIsMenuOpen] = useState(false);

  const containerRef = useRef<HTMLDivElement>(null);
  useClickAway(
    containerRef,
    () => {
      setIsMenuOpen(false);
    },
    ['mouseover'],
  );

  const handlePointerEnter = useCallback(() => {
    setIsMenuOpen(true);
  }, []);

  return (
    <div ref={containerRef} className="relative">
      <button onPointerEnter={handlePointerEnter}>
        <SubNavItem
          isArrowEnabled
          isArrowActive={isMenuOpen}
          className="ps-6 pe-0"
        >
          {selectedOption}
        </SubNavItem>
      </button>
      <div
        className={cn(
          'absolute top-full left-0 w-max z-10',
          'rounded-none flex flex-col gap-y-4 bg-spaceGrey/600 border-none max-tablet-sm:w-[180px]',
          'px-6 py-3 tablet-sm:py-5 desktop-sm:py-6',
          !isMenuOpen && 'hidden',
        )}
      >
        {options.map(option => (
          <MenuItem
            key={option.id}
            option={option}
            handleClick={handleClick}
            selectedOption={selectedOption}
          />
        ))}
      </div>
    </div>
  );
};

export default Menu;
