import * as React from 'react';
import { MoreHorizontal } from 'lucide-react';

import { VariantProps, cva } from 'class-variance-authority';
import { cn } from '@/lib/tailwind';
import Icon from '@/external/icon';

const Pagination = ({ className, ...props }: React.ComponentProps<'nav'>) => (
  <nav
    role="navigation"
    aria-label="pagination"
    className={cn('mx-auto flex w-full justify-center', className)}
    {...props}
  />
);
Pagination.displayName = 'Pagination';

const PaginationContent = React.forwardRef<
  HTMLUListElement,
  React.ComponentProps<'ul'>
>(({ className, ...props }, ref) => (
  <ul
    ref={ref}
    className={cn(
      'flex flex-row items-center gap-[14px] tablet-sm:gap-[26px] desktop-sm:gap-6 desktop-lg:gap-[26px]',
      className,
    )}
    {...props}
  />
));
PaginationContent.displayName = 'PaginationContent';

const paginationItemVariants = cva(
  `inline-flex items-center justify-center
  fill-current cursor-pointer`,
  {
    variants: {
      variant: {
        default:
          'bg-paleSilver text-black border-paleSilver hover:text-white hover:bg-primaryOceanBlue/80 transition-colors',
        active: 'bg-white border-primaryOceanBlue text-primaryOceanBlue',
        disabled:
          'bg-mediumGrey border-mediumGrey pointer-events-none cursor-none',
        highlighted: 'bg-primaryOceanBlue border-primaryOceanBlue text-white',
        grey: 'bg-primarySpaceGrey border-primarySpaceGrey text-white',
      },
      size: {
        default: `w-8 h-8 tablet-sm:w-14 tablet-sm:h-14 desktop-lg:w-16 desktop-lg:h-16
        rounded-sm tablet-sm:rounded-[6px]
        border-[0.5px] tablet-sm:border-[1px]`,
        sm: 'w-7 h-7',
      },
    },
    defaultVariants: {
      variant: 'default',
      size: 'default',
    },
  },
);

export interface PaginationItemProps
  extends React.LiHTMLAttributes<HTMLLIElement>,
    VariantProps<typeof paginationItemVariants> {
  disabled?: boolean;
}

const PaginationItem = React.forwardRef<HTMLLIElement, PaginationItemProps>(
  ({ className, variant, size, children, disabled, ...props }, ref) => (
    <li
      ref={ref}
      {...props}
      className={cn('inline', disabled && 'pointer-events-none')}
    >
      <button
        className={cn(paginationItemVariants({ variant, size, className }), '')}
        disabled={disabled}
      >
        {children}
      </button>
    </li>
  ),
);
PaginationItem.displayName = 'PaginationItem';

type PaginationLinkProps = {
  isActive?: boolean;
} & React.ComponentProps<'a'>;

const PaginationLink = ({
  className,
  isActive,
  ...props
}: PaginationLinkProps) => (
  <a
    aria-current={isActive ? 'page' : undefined}
    className={cn(className)}
    {...props}
  />
);
PaginationLink.displayName = 'PaginationLink';

const PaginationPrevious = ({
  className,
  ...props
}: React.ComponentProps<typeof PaginationLink>) => (
  <span aria-label="Go to previous page" className={className} {...props}>
    <Icon
      icon="arrowLeft"
      className="fill-white w-[10px] h-[10px] tablet-sm:w-[22px] tablet-sm:h-[20px] desktop-sm:w-[18px] desktop-sm:h-[18px] desktop-lg:w-[22px] desktop-lg:h-[20px]"
    />
    <span aria-hidden="true" className="sr-only">
      Previous
    </span>
  </span>
);
PaginationPrevious.displayName = 'PaginationPrevious';

const PaginationNext = ({
  className,
  ...props
}: React.ComponentProps<typeof PaginationLink>) => (
  <span aria-label="Go to next page" className={className} {...props}>
    <span aria-hidden="true" className="sr-only">
      Next
    </span>
    <Icon
      icon="arrowRight"
      className="fill-white w-[10px] h-[10px] tablet-sm:w-[22px] tablet-sm:h-[20px] desktop-sm:w-[18px] desktop-sm:h-[18px] desktop-lg:w-[22px] desktop-lg:h-[20px]"
    />
  </span>
);
PaginationNext.displayName = 'PaginationNext';

const PaginationEllipsis = ({
  className,
  ...props
}: React.ComponentProps<'span'>) => (
  <span
    aria-hidden
    className={cn('flex h-9 w-9 items-center justify-center', className)}
    {...props}
  >
    <MoreHorizontal className="h-4 w-4" />
    <span className="sr-only">More pages</span>
  </span>
);
PaginationEllipsis.displayName = 'PaginationEllipsis';

export {
  Pagination,
  PaginationContent,
  PaginationEllipsis,
  PaginationItem,
  PaginationLink,
  PaginationNext,
  PaginationPrevious,
};
