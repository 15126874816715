'use client';

import { useContext } from 'react';
import { useTranslations } from 'next-intl';
import { PageContext } from '@/contexts/PageContext';
import {
  getPathSegmentsFromFeaturePageSlug,
  getProductOverviewPageSlug,
  isFeatureSlug,
} from '@/utils/product';
import Breadcrumb, { Crumb } from '@/components/breadcrumb';
import { PRODUCT_SLUG_MAP } from '../constants';

export const Breadcrumbs = () => {
  const { slug, title } = useContext(PageContext);
  const t = useTranslations();

  const [productSlug] = getPathSegmentsFromFeaturePageSlug(slug);
  const productName = Object.entries(PRODUCT_SLUG_MAP).find(([, slug]) => {
    return slug === productSlug;
  })?.[0];

  const crumbs = [
    {
      label: t('Home'),
      link: '/',
      icon: 'home',
    } as Crumb,
    isFeatureSlug(slug) && productName
      ? ({
          label: productName,
          link: getProductOverviewPageSlug({ product: productSlug }),
        } as Crumb)
      : null,
    {
      label: title,
    } as Crumb,
  ].filter((crumb): crumb is Crumb => !!crumb);

  return (
    <Breadcrumb
      items={crumbs}
      className="absolute tablet-sm:top-8 tablet-sm:start-8 desktop-lg:top-10 desktop-lg:start-10"
    />
  );
};
