'use client';

import { ReactNode, useContext } from 'react';
import { HomeHeroContext } from '@/components/widgets/HomeHero/contexts/HomeHeroContext';
import { cn } from '@/lib/tailwind';

const HeroContent = ({ children }: { children: ReactNode }) => {
  const { isBackgroundImageLoaded } = useContext(HomeHeroContext);

  return (
    <div
      className={cn(
        'transition-[opacity,transform] duration-500',
        isBackgroundImageLoaded
          ? 'opacity-100 scale-100 '
          : 'opacity-0 scale-90',
      )}
    >
      {children}
    </div>
  );
};

export default HeroContent;
