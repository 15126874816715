import {
  DropdownMenu,
  DropdownMenuTrigger,
  DropdownMenuContent,
} from '@radix-ui/react-dropdown-menu';
import { useCallback, useState } from 'react';
import {
  Accordion,
  AccordionContent,
  AccordionItem,
  AccordionTrigger,
} from '@/components/ui/accordion';
import { cn } from '@/lib/tailwind';
import Icon from '@/external/icon';
import SubNavItem from '@/components/subNav/SubNavItem';
import { FilterItemProps, SubNavFilter } from '../types';

const FilterItem = ({
  option,
  handleClick,
  selectedOption,
}: FilterItemProps) => {
  const onClick = useCallback(() => {
    handleClick(option.id);
  }, [handleClick, option.id]);
  return (
    <div
      key={option.id}
      onClick={onClick}
      className={cn(
        'block text-mediumGrey uppercase text-mobile-sm/label-2 tablet-sm:text-tablet-sm/overline-3',
        selectedOption === option.label && 'text-primaryOceanBlue',
      )}
    >
      {option.label}
    </div>
  );
};

const AccordionMenu = ({
  home,
  filterMenu,
}: Omit<SubNavFilter, 'handleSearchInputChange'>) => {
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  return (
    <DropdownMenu modal={false} open={isMenuOpen} onOpenChange={setIsMenuOpen}>
      <DropdownMenuTrigger className="w-full">
        <SubNavItem
          isArrowEnabled
          isArrowActive={isMenuOpen}
          className="justify-between w-full border-e border-cometBlack"
        >
          {home.label}
        </SubNavItem>
      </DropdownMenuTrigger>
      <DropdownMenuContent
        sideOffset={0}
        align="start"
        className="rounded-none bg-spaceGrey/600 border-none"
      >
        <Accordion
          type="single"
          collapsible
          className="w-[180px] tablet-sm:w-[226px] px-6 py-3 tablet-sm:py-5 desktop-sm:py-6 "
        >
          {filterMenu.map(
            ({ options, handleClick, label, selectedOption }, index) => {
              return (
                <AccordionItem
                  key={label}
                  value={label}
                  className="border-none"
                >
                  <AccordionTrigger
                    className={cn(
                      'text-lightGrey uppercase hover:no-underline text-mobile-sm/label-2 tablet-sm:text-tablet-sm/overline-3 py-0',
                      index > 0 && 'pt-3.5',
                    )}
                  >
                    {selectedOption}
                    <Icon
                      icon="chevron"
                      className={cn('w-3 ms-3 fill-white transition-transform')}
                    />
                  </AccordionTrigger>
                  <AccordionContent
                    className={'ps-3'}
                    containerClassname={'p-0'}
                  >
                    <div className="flex flex-col gap-y-2.5 pt-2.5">
                      {options.map(option => (
                        <FilterItem
                          key={option.id}
                          option={option}
                          handleClick={handleClick}
                          selectedOption={selectedOption}
                        />
                      ))}
                    </div>
                  </AccordionContent>
                </AccordionItem>
              );
            },
          )}
        </Accordion>
      </DropdownMenuContent>
    </DropdownMenu>
  );
};

export default AccordionMenu;
