'use client';
import { useConfigure } from 'react-instantsearch';
import { useCallback, useState } from 'react';
import Icon from '@/external/icon';
import { cn } from '@/lib/tailwind';
import {
  AccordionContent,
  AccordionItem,
  AccordionTrigger,
} from '@/components/ui/accordion';
import { EVENT_STATE, ON_DEMAND_LABEL, UPCOMING_LABEL } from '../constants';
import { EventStateType } from '../types';
import FilterCheckbox from './FilterCheckBox';

type Props = {
  name: string;
};

const CustomDateFilterMenu = ({ name }: Props) => {
  const [eventState, setEventState] = useState<EventStateType>(EVENT_STATE.ALL);
  const noOfSelectedOptions = eventState === EVENT_STATE.ALL ? 0 : 1;

  const toggleEventState = useCallback((state: EventStateType) => {
    setEventState(prevState => (prevState === state ? EVENT_STATE.ALL : state));
  }, []);

  const handleOnDemand = useCallback(
    () => toggleEventState(EVENT_STATE.ON_DEMAND),
    [toggleEventState],
  );
  const handleUpcoming = useCallback(
    () => toggleEventState(EVENT_STATE.UPCOMING),
    [toggleEventState],
  );

  const currentTimestamp = Math.floor(new Date().getTime() / 1000);

  useConfigure({
    filters:
      eventState === EVENT_STATE.UPCOMING
        ? `eventDate>${currentTimestamp}`
        : eventState === EVENT_STATE.ON_DEMAND
        ? `eventDate<=${currentTimestamp}`
        : undefined,
  });

  return (
    <AccordionItem value={name} className="border-none flex-1 relative z-20">
      <AccordionTrigger
        className={cn(
          'bg-white border rounded-[4px] justify-between items-center',
          'px-2.5 tablet-sm:px-4 desktop-sm:px-2.5 desktop-lg:px-4',
          'py-2 tablet-sm:py-2.5 desktop-sm:py-3',
          'hover:no-underline',
          'z-10 relative',
        )}
      >
        <div
          className={cn(
            'text-mobile-sm/overline-2 tablet-sm:text-tablet-sm/label-1 desktop-lg:text-desktop-large/overline-3',
            'flex items-center',
          )}
        >
          <p className="uppercase">{name}</p>
          {noOfSelectedOptions > 0 && (
            <div
              className={cn(
                'text-white bg-primaryOceanBlue rounded-full',
                'tabular-nums text-center ms-2 px-1',
                'h-[18px] tablet-sm:h-4 desktop-lg:h-[18px]',
                'leading-[18px] tablet-sm:leading-4 desktop-lg:leading-[18px]',
              )}
            >
              {noOfSelectedOptions}
            </div>
          )}
        </div>
        <Icon
          icon="arrowTriangle"
          className={cn(
            'aspect-square fill-charcoalBlue rotate-90 flex-none',
            'w-2 tablet-sm:w-2.5',
          )}
        />
      </AccordionTrigger>
      <AccordionContent
        className={cn(
          'border border-t-0 rounded-b-[4px] bg-white',
          'p-4 tablet-sm:pt-2.5 desktop-sm:pt-4',
          'relative before:block',
          'before:h-1 -top-1',
          'tablet-sm:max-desktop-sm:absolute tablet-sm:max-desktop-sm:top-[calc(100%-4px)] w-full',
        )}
        containerClassname="pb-0"
      >
        <div
          className={cn(
            'overflow-auto space-y-2 filter-scrollbar',
            'max-h-[104px] desktop-sm:max-h-[216px] desktop-lg:max-h-[208px]',
            'uppercase text-mobile-sm/overline-2',
            'tablet-sm:text-tablet-sm/label-1 desktop-lg:text-desktop-large/overline-3',
            'pe-4 tablet-sm:pe-2.5',
          )}
        >
          <FilterCheckbox
            label={ON_DEMAND_LABEL}
            checked={eventState === EVENT_STATE.ON_DEMAND}
            onChange={handleOnDemand}
          />
          <FilterCheckbox
            label={UPCOMING_LABEL}
            checked={eventState === EVENT_STATE.UPCOMING}
            onChange={handleUpcoming}
          />
        </div>
      </AccordionContent>
    </AccordionItem>
  );
};

export default CustomDateFilterMenu;
