const COLUMN_COUNT = {
  mobile: 1,
  tablet: 2,
  desktop: 3,
};

function isIndexInLastRow(totalItems: number, columns: number, index: number) {
  const rows = Math.ceil(totalItems / columns);
  const startIndexLastRow = (rows - 1) * columns;
  return index >= startIndexLastRow;
}

export const calculateLastRowStates = (itemsLength: number, index: number) => {
  return {
    isLastRowMobile: isIndexInLastRow(itemsLength, COLUMN_COUNT.mobile, index),
    isLastRowTablet: isIndexInLastRow(itemsLength, COLUMN_COUNT.tablet, index),
    isLastRowDesktop: isIndexInLastRow(
      itemsLength,
      COLUMN_COUNT.desktop,
      index,
    ),
  };
};
