'use client';
import { useRefinementList } from 'react-instantsearch';
import { ChangeEvent, useEffect, useMemo, useState } from 'react';
import Icon from '@/external/icon';
import { cn } from '@/lib/tailwind';
import {
  AccordionContent,
  AccordionItem,
  AccordionTrigger,
} from '@/components/ui/accordion';
import {
  NO_SEARCH_RESULTS_MESSAGE,
  DEFAULT_FILTER_OPERATOR,
} from '../constants';
import { AlgoliaFilterOption } from './AlgoliaFilterOption';
import { FilterSearchBox } from './FilterSearchBox';

type Props = {
  name: string;
  isExpanded: boolean;
};

export const FilterMenu = ({ name, isExpanded }: Props) => {
  const [filterQuery, setFilterQuery] = useState('');
  const { items, refine } = useRefinementList({
    attribute: name,
    operator: DEFAULT_FILTER_OPERATOR,
  });

  useEffect(() => {
    if (!isExpanded) {
      setFilterQuery('');
    }
  }, [isExpanded]);

  const noOfSelectedOptions = useMemo(
    () => items.filter(item => item.isRefined).length ?? 0,
    [items],
  );

  const filteredItems = useMemo(
    () =>
      items.filter(item => {
        if (filterQuery === '') {
          return true;
        }

        return item.label.toLowerCase().includes(filterQuery);
      }),
    [items, filterQuery],
  );

  const handleSearchQueryChange = (event: ChangeEvent<HTMLInputElement>) => {
    setFilterQuery(event.currentTarget.value.toLowerCase());
  };

  return (
    <AccordionItem value={name} className="border-none flex-1 relative z-20">
      <AccordionTrigger
        className={cn(
          'bg-white border rounded-[4px] justify-between items-center',
          'px-2.5 tablet-sm:px-4 desktop-sm:px-2.5 desktop-lg:px-4',
          'py-2 tablet-sm:py-2.5 desktop-sm:py-3',
          'hover:no-underline',
          'z-10 relative',
        )}
      >
        <div
          className={cn(
            'text-mobile-sm/overline-2 tablet-sm:text-tablet-sm/label-1 desktop-lg:text-desktop-large/overline-3',
            'flex items-center',
          )}
        >
          <p className="uppercase">{name}</p>
          {noOfSelectedOptions > 0 ? (
            <div
              className={cn(
                'text-white bg-primaryOceanBlue rounded-full',
                'tabular-nums text-center ms-2 px-1',
                'h-[18px] tablet-sm:h-4 desktop-lg:h-[18px]',
                'leading-[18px] tablet-sm:leading-4 desktop-lg:leading-[18px]',
              )}
            >
              {noOfSelectedOptions}
            </div>
          ) : null}
        </div>
        <Icon
          icon="arrowTriangle"
          className={cn(
            'aspect-square fill-[#585F66] rotate-90 flex-none',
            'w-2 tablet-sm:w-2.5',
          )}
        />
      </AccordionTrigger>
      <AccordionContent
        className={cn(
          'border border-t-0 rounded-b-[4px] bg-white',
          'p-4 tablet-sm:pt-2.5 desktop-sm:pt-4',
          'relative before:block',
          'before:h-1 -top-1',
          'tablet-sm:max-desktop-sm:absolute tablet-sm:max-desktop-sm:top-[calc(100%-4px)] w-full',
        )}
        containerClassname="pb-0"
      >
        {items.length > 4 ? (
          <FilterSearchBox
            value={filterQuery}
            onChange={handleSearchQueryChange}
            filterName={name}
          />
        ) : null}
        <div
          className={cn(
            'overflow-auto space-y-2 filter-scrollbar',
            'max-h-[104px] desktop-sm:max-h-[216px] desktop-lg:max-h-[208px]',
            'uppercase text-mobile-sm/overline-2',
            'tablet-sm:text-tablet-sm/label-1 desktop-lg:text-desktop-large/overline-3',
            'pe-4 tablet-sm:pe-2.5',
          )}
        >
          {filteredItems.length > 0 ? (
            filteredItems.map(item => {
              return (
                <AlgoliaFilterOption
                  key={item.label}
                  item={item}
                  onClick={refine}
                />
              );
            })
          ) : (
            <span className="text-mediumGrey">{NO_SEARCH_RESULTS_MESSAGE}</span>
          )}
        </div>
      </AccordionContent>
    </AccordionItem>
  );
};
