import { useState, useMemo, useCallback } from 'react';
import { NewsroomFilterableGridItem } from '../types';

// TODO: make it extendable for other filters
export const useNewsFilters = (
  newsItems: NewsroomFilterableGridItem[],
  defaultFilterOption: string,
  defaultYearOption: string,
  setCurrentPage: (page: number) => void,
) => {
  const [selectedFilterOption, setSelectedFilterOption] =
    useState<string>(defaultFilterOption);
  const [selectedUniqueYearsOption, setSelectedUniqueYearsOption] =
    useState<string>(defaultYearOption);
  const [searchInput, setSearchInput] = useState<string>('');

  const filteredItems = useMemo(() => {
    const filterByType = (item: NewsroomFilterableGridItem) =>
      selectedFilterOption === 'ALL' || item.type === selectedFilterOption;

    const filterByYear = (item: NewsroomFilterableGridItem) =>
      selectedUniqueYearsOption
        ? selectedUniqueYearsOption === 'ALL' ||
          item.publishedOn.slice(0, 4) === selectedUniqueYearsOption
        : true;

    const filterBySearchInput = (item: NewsroomFilterableGridItem) =>
      item.title.toLowerCase().includes(searchInput.toLowerCase());

    return newsItems
      .filter(filterByType)
      .filter(filterByYear)
      .filter(filterBySearchInput);
  }, [newsItems, selectedFilterOption, selectedUniqueYearsOption, searchInput]);

  const handleNewsFilterChange = useCallback(
    (selectedOption: string) => {
      setSelectedFilterOption(selectedOption);
      setCurrentPage(1);
    },
    [setCurrentPage],
  );

  const handleUniqueYearsFilterChange = useCallback(
    (selectedOption: string) => {
      setSelectedUniqueYearsOption(selectedOption);
      setCurrentPage(1);
    },
    [setCurrentPage],
  );

  const handleSearchInputChange = useCallback(
    (searchInput: string) => {
      setSearchInput(searchInput);
      setCurrentPage(1);
    },
    [setCurrentPage],
  );

  return {
    filteredItems,
    selectedFilterOption,
    selectedUniqueYearsOption,
    handleNewsFilterChange,
    handleUniqueYearsFilterChange,
    handleSearchInputChange,
  };
};
