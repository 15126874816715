export const NEWSROOM_BASE_SLUG = 'newsroom';

export const NEWS_ITEMS_TO_DISPLAY_PER_PAGE = 15;

export const NEWS_FILTER_LABEL = 'PRESS RELEASE';
export const UNIQUE_YEARS_LABEL = 'YEAR';

export const SEARCH_PLACEHOLDER = 'SEARCH';

export const NEWS_ITEM_CONTENT_TYPE = 'newsItem';

export const GRID_OFFSET = 150;
