import { useMemo, useCallback } from 'react';
import { useRouter } from 'next/navigation';
import { GRID_OFFSET } from '../constants';

interface UsePaginationProps<T> {
  items: T[];
  itemsPerPage: number;
  containerRef: React.RefObject<HTMLElement>;
  currentPage: number;
  setCurrentPage: (page: number) => void;
}

export const usePagination = <T>({
  items,
  itemsPerPage,
  containerRef,
  currentPage,
  setCurrentPage,
}: UsePaginationProps<T>) => {
  const router = useRouter();

  const noOfPages = useMemo(
    () => Math.ceil(items.length / itemsPerPage),
    [items.length, itemsPerPage],
  );

  const displayedItems = useMemo(() => {
    const startIndex = (currentPage - 1) * itemsPerPage;
    const endIndex = startIndex + itemsPerPage;
    return items.slice(startIndex, endIndex);
  }, [items, currentPage, itemsPerPage]);

  const scrollToGrid = useCallback(() => {
    if (containerRef.current) {
      const element = containerRef.current;
      const y =
        element.getBoundingClientRect().top + window.scrollY - GRID_OFFSET;
      window.scrollTo({ top: y, behavior: 'smooth' });
    }
  }, [containerRef]);

  const handlePageNavigation = useCallback(
    (newPage: number, scrollToTop = true) => {
      if (newPage === currentPage || newPage < 1 || newPage > noOfPages) return;
      setCurrentPage(newPage);
      router.push(`?page=${newPage}`, { scroll: false });
      if (scrollToTop) {
        scrollToGrid();
      }
    },
    [currentPage, noOfPages, router, scrollToGrid, setCurrentPage],
  );

  return {
    currentPage,
    displayedItems,
    noOfPages,
    handlePageNavigation,
  };
};
