'use client';

import React, { useCallback, useMemo, useRef, useState } from 'react';
import { useSearchParams } from 'next/navigation';
import MaxWidthContainer from '@/components/MaxWidthContainer';
import { SubNavFilter } from '@/components/subNavFilter';
import NoResultsFallback from '@/components/algolia/NoResultsFallback';
import { NewsroomFilterableGridProps } from '../types';
import { NEWS_ITEMS_TO_DISPLAY_PER_PAGE } from '../constants';
import { useNewsFilters } from '../hooks/useNewsFilters';
import { usePagination } from '../hooks/usePagination';
import { createFilterMenu } from '../utils/filterMenu';
import Grid from './Grid';
import Pagination from './Pagination';

const NewsroomFilterableGridClient = ({
  newsItems,
  newsFilterOptions,
  uniqueYears,
  newsFilterLabel,
  uniqueYearsLabel,
  subNavLink,
  searchPlaceholder,
}: NewsroomFilterableGridProps) => {
  const containerRef = useRef<HTMLDivElement>(null);
  const searchParams = useSearchParams();
  const initialNoOfPages = useMemo(
    () => Math.ceil(newsItems.length / NEWS_ITEMS_TO_DISPLAY_PER_PAGE),
    [newsItems],
  );
  const initialPage = +(searchParams.get('page') || '1');

  const [currentPage, setCurrentPage] = useState(
    initialPage > initialNoOfPages ? 1 : initialPage,
  );

  const {
    filteredItems,
    selectedFilterOption,
    selectedUniqueYearsOption,
    handleNewsFilterChange,
    handleUniqueYearsFilterChange,
    handleSearchInputChange,
  } = useNewsFilters(
    newsItems,
    newsFilterOptions[0].id,
    uniqueYears[0].id,
    setCurrentPage,
  );

  const { displayedItems, noOfPages, handlePageNavigation } = usePagination({
    items: filteredItems,
    itemsPerPage: NEWS_ITEMS_TO_DISPLAY_PER_PAGE,
    containerRef,
    currentPage,
    setCurrentPage,
  });

  const filterMenu = useMemo(
    () =>
      createFilterMenu({
        newsFilterLabel,
        newsFilterOptions,
        uniqueYears,
        uniqueYearsLabel,
        selectedFilterOption,
        selectedUniqueYearsOption,
        handleNewsFilterChange,
        handleUniqueYearsFilterChange,
      }),
    [
      newsFilterLabel,
      newsFilterOptions,
      uniqueYears,
      uniqueYearsLabel,
      selectedFilterOption,
      selectedUniqueYearsOption,
      handleNewsFilterChange,
      handleUniqueYearsFilterChange,
    ],
  );

  const onNext = useCallback(() => {
    handlePageNavigation(currentPage + 1);
  }, [handlePageNavigation, currentPage]);

  const onPrevious = useCallback(() => {
    handlePageNavigation(currentPage - 1);
  }, [handlePageNavigation, currentPage]);

  return (
    <div>
      <SubNavFilter
        home={subNavLink}
        filterMenu={filterMenu}
        handleSearchInputChange={handleSearchInputChange}
        searchPlaceHolder={searchPlaceholder}
      />
      <MaxWidthContainer ref={containerRef}>
        {displayedItems.length > 0 ? (
          <>
            <Grid newsItems={displayedItems} />
            <Pagination
              noOfPages={noOfPages}
              currentPage={currentPage}
              onNext={onNext}
              onPrevious={onPrevious}
              onPageChange={handlePageNavigation}
            />
          </>
        ) : (
          <NoResultsFallback className="mt-12 tablet-sm:mt-16 desktop-sm:mt-20" />
        )}
      </MaxWidthContainer>
    </div>
  );
};

export default NewsroomFilterableGridClient;
