'use client';
import { ChangeEvent } from 'react';
import { useTranslations } from 'next-intl';
import Icon from '@/external/icon';
import { cn } from '@/lib/tailwind';

export const FilterSearchBox = ({
  filterName,
  value,
  onChange,
}: {
  filterName: string;
  value: string;
  onChange: (event: ChangeEvent<HTMLInputElement>) => void;
}) => {
  const t = useTranslations();
  const searchPlaceholder = t(`Search ${filterName}`);
  return (
    <div className="relative mb-4 desktop-lg:mb-2.5">
      <input
        className={cn(
          'w-full pb-1 border-b border-whiteSmoke uppercase',
          'placeholder:text-lightGrey focus-within:outline-none',
          'text-mobile-sm/overline-1 tablet-sm:text-tablet-sm/label-1 desktop-lg:text-desktop-large/overline-3',
          'placeholder:text-mobile-sm/overline-2 tablet-sm:placeholder:text-tablet-sm/label-2 desktop-lg:placeholder:text-desktop-large/label-1',
        )}
        type="text"
        autoComplete="off"
        autoCorrect="off"
        autoCapitalize="off"
        spellCheck={false}
        placeholder={searchPlaceholder}
        onChange={onChange}
        value={value}
      />
      <Icon
        icon="searchV2"
        className="w-3.5 h-3.5 absolute top-1/2 right-0 -translate-y-1/2 fill-mediumGrey"
      />
    </div>
  );
};
