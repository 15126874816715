'use client';
import { useCallback, useEffect, useState } from 'react';
import Icon from '@/external/icon';
import { ShareChannel, SHARE_LINK_CONFIG_PER_CHANNEL } from '../constants';

const POPUP_WINDOW_WIDTH = 520;
const POPUP_WINDOW_HEIGHT = 570;

const ShareLink = ({
  channel,
  linkTitle,
}: {
  channel: ShareChannel;
  linkTitle: string;
}) => {
  const [url, setUrl] = useState('');

  useEffect(() => {
    setUrl(window.location.href);
  }, []);

  const { icon, shareLinkGenerator, isOpenInNewWindow } =
    SHARE_LINK_CONFIG_PER_CHANNEL[channel];

  const shareLink = shareLinkGenerator({ linkTitle, link: url });

  const openNewWindow = useCallback(() => {
    if (!isOpenInNewWindow) {
      return;
    }
    // Inspo to center: https://stackoverflow.com/a/14132265

    const left = (window.innerWidth - POPUP_WINDOW_WIDTH) / 2;
    const top = (window.innerHeight - POPUP_WINDOW_HEIGHT) / 2;
    return window.open(
      shareLink,
      '',
      `width=${POPUP_WINDOW_WIDTH}, height=${POPUP_WINDOW_HEIGHT}, top=${top}, left=${left}, toolbar=no, location=no, directories=no, status=no, menubar=no, scrollbars=no, resizable=no, copyhistory=no, popup=true`,
    );
  }, [shareLink, isOpenInNewWindow]);

  return (
    <a
      href={isOpenInNewWindow ? undefined : shareLink}
      onClick={isOpenInNewWindow ? openNewWindow : undefined}
      className="cursor-pointer"
    >
      <div className="bg-primarySpaceGrey w-10 p-2.5 aspect-square rounded-full hover:bg-primarySpaceGrey/90">
        <Icon icon={icon} className="w-5 aspect-square fill-white " />
      </div>
    </a>
  );
};

export default ShareLink;
