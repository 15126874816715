import React, { ReactNode, forwardRef } from 'react';
import { cn } from '@/lib/tailwind';

type Props = {
  className?: string;
  children: ReactNode;
  as?:
    | 'h1'
    | 'h2'
    | 'h3'
    | 'h4'
    | 'h5'
    | 'h6'
    | 'span'
    | 'p'
    | 'div'
    | 'ol'
    | 'ul';
};

const MaxWidthContainer = forwardRef<HTMLElement, Props>(
  ({ className, children, as = 'div' }: Props, ref) => {
    const As = as;
    return (
      <As
        ref={ref as any} // Type assertion needed due to polymorphic element type
        className={cn(
          'w-full max-w-mobile-limit tablet-sm:max-w-tablet-sm-limit desktop-sm:max-w-desktop-sm-limit desktop-lg:max-w-desktop-lg-limit mx-auto',
          className,
        )}
      >
        {children}
      </As>
    );
  },
);

MaxWidthContainer.displayName = 'MaxWidthContainer';

export default MaxWidthContainer;
