import { ReactNode } from 'react';
import Icon from '@/external/icon';
import { cn } from '@/lib/tailwind';

const SubNavItem = ({
  children,
  isArrowEnabled = false,
  isArrowActive = false,
  className,
}: {
  children: ReactNode;
  isArrowEnabled?: boolean;
  isArrowActive?: boolean;
  className?: string;
}) => {
  return (
    <div className={cn('px-6 py-3 tablet-sm:py-5 flex', className)}>
      <div
        className={
          'whitespace-nowrap text-white uppercase text-mobile-sm/label-2 tablet-sm:text-tablet-sm/overline-3 desktop-lg:text-desktop-large/overline-3'
        }
      >
        {children}
      </div>
      {isArrowEnabled ? (
        <Icon
          icon="chevron"
          className={cn(
            'w-3 ms-3 fill-white transition-transform',
            isArrowActive && '-scale-100',
          )}
        />
      ) : null}
    </div>
  );
};

export default SubNavItem;
