'use client';

import { useCallback } from 'react';
import LinkAction from '@/components/CallToAction/behaviour/LinkAction';
import MaxWidthContainer from '@/components/MaxWidthContainer';
import SubNavItem from '@/components/subNav/SubNavItem';
import { HeaderHeightListener } from '@/contexts/HeaderHeightContext';
import { SearchBox } from '../searchbox';
import { SubNavFilter } from './types';
import Menu from './components/Menu';
import AccordionMenu from './components/AccordionMenu';

const SubNavFilter = ({
  home,
  filterMenu,
  handleSearchInputChange,
  searchPlaceHolder,
}: SubNavFilter) => {
  const onSearchInputChange = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      handleSearchInputChange(event.target.value);
    },
    [handleSearchInputChange],
  );

  return (
    <div className="w-full bg-primarySpaceGrey relative z-10">
      <MaxWidthContainer className="flex items-center justify-between tablet-sm:gap-x-4">
        <div className="flex h-full">
          <div className="flex max-tablet-sm:hidden">
            <LinkAction url={home.url} containerClassName="flex-none">
              <SubNavItem>{home.label}</SubNavItem>
            </LinkAction>
            <div className="w-px bg-cometBlack" />
          </div>
          <div className="max-tablet-sm:hidden flex">
            {filterMenu.map(menu => {
              return <Menu key={menu.label} {...menu} />;
            })}
          </div>
          <div className="tablet-sm:hidden h-full max-tablet-sm:flex-1">
            <AccordionMenu home={home} filterMenu={filterMenu} />
          </div>
        </div>
        <SearchBox
          onChange={onSearchInputChange}
          containerClassName="ms-3 my-[5px] px-2.5 py-2 tablet-sm:my-3 tablet-sm:px-4 max-tablet-sm:px-2.5 w-[170px] tablet-sm:w-[216px] desktop-lg:py-2.5"
          className="placeholder:text-mobile-sm/label-2 placeholder:tablet-sm:text-tablet-sm/overline-3 placeholder:desktop-lg:text-desktop-large/overline-3"
          placeholder={searchPlaceHolder}
        />
      </MaxWidthContainer>
    </div>
  );
};

const SubNavFilterWrapper = (subNavData: SubNavFilter) => {
  return (
    <HeaderHeightListener>
      <div className="sticky top-[--header-height] z-10">
        <SubNavFilter {...subNavData} />
      </div>
    </HeaderHeightListener>
  );
};

export default SubNavFilterWrapper;
