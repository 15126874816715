import { useMemo } from 'react';
import {
  Pagination as PaginationContainer,
  PaginationContent,
  PaginationItem,
  PaginationNext,
  PaginationPrevious,
} from '@/components/ui/pagination';
import generatePaginationRange from '../../paginatedGrid/utils/generatePaginationRange';
import { MAX_PAGES_TO_SHOW } from '../../paginatedGrid/constants';

type Props = {
  noOfPages: number;
  currentPage: number;
  onNext: () => void;
  onPrevious: () => void;
  onPageChange: (page: number) => void;
};

const Pagination = ({
  noOfPages,
  currentPage,
  onNext,
  onPrevious,
  onPageChange,
}: Props) => {
  const previousPageIndex = currentPage - 1;
  const nextPageIndex = currentPage + 1;
  const isLastPage = currentPage === noOfPages;
  const isFirstPage = currentPage === 1;

  const pageNumbers = useMemo(
    () => generatePaginationRange(noOfPages, currentPage, MAX_PAGES_TO_SHOW),
    [noOfPages, currentPage],
  );

  const renderPaginationLink = (page: number, isCurrentPage: boolean) => (
    <PaginationItem
      key={page}
      value={page}
      variant={isCurrentPage ? 'active' : 'default'}
      onClick={() => onPageChange(page)}
    >
      <span className="text-mobile-sm/body-3 tablet-sm:text-tablet-sm/body-1 desktop-lg:text-desktop-large/body-1">
        {page}
      </span>
    </PaginationItem>
  );

  return (
    <PaginationContainer className="mt-11 tablet-sm:mt-8">
      <PaginationContent>
        <PaginationItem
          onClick={onPrevious}
          value={previousPageIndex}
          variant={isFirstPage ? 'disabled' : 'highlighted'}
          disabled={isFirstPage}
        >
          <PaginationPrevious />
        </PaginationItem>

        {pageNumbers.map(page =>
          renderPaginationLink(page, page === currentPage),
        )}

        <PaginationItem
          onClick={onNext}
          value={nextPageIndex}
          variant={isLastPage ? 'disabled' : 'highlighted'}
          disabled={isLastPage}
        >
          <PaginationNext />
        </PaginationItem>
      </PaginationContent>
    </PaginationContainer>
  );
};

export default Pagination;
