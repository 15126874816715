'use client';
import { ReactNode, useContext } from 'react';
import { CurveSectionWithGradientShadow } from '@/components/CurveSectionWithGradientShadow';
import { HomeHeroContext } from '../contexts/HomeHeroContext';

const CurvedShadow = ({ children }: { children: ReactNode }) => {
  const { isBackgroundImageLoaded } = useContext(HomeHeroContext);
  return (
    <CurveSectionWithGradientShadow isShadowEnabled={isBackgroundImageLoaded}>
      {children}
    </CurveSectionWithGradientShadow>
  );
};

export default CurvedShadow;
