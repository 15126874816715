'use client';

import { useEffect, useState, useMemo } from 'react';
import dynamic from 'next/dynamic';
import { ReactPlayerProps } from 'react-player/lazy';
import PlayIcon from './components/PlayIcon';
import { VideoProps } from './types';
import { FIXED_ASPECT_RATIO_FOR_VIDEO_MODAL } from './constants';

const ReactPlayer = dynamic(() => import('react-player/lazy'), { ssr: false });

// TODO: Add i18n
const Video = ({
  source,
  loopVideo,
  poster,
  isAutoplay,
  styles,
  muted,
  playsinline,
  isModal = false,
  isOverlay = false,
}: VideoProps) => {
  const [isPlaying, setIsPlaying] = useState(false);

  /*
    Setting the `playing` prop to true during the initial render of React Player results in the playback of multiple video instances,
    causing echoes. To prevent this issue, it is advisable to set the playing prop to true after the initial render to enable autoplay.
  */

  useEffect(() => {
    if (isAutoplay) {
      setIsPlaying(true);
    }
  }, [isAutoplay]);

  const isFixedAspectRatio = isModal;

  const reactPlayerProps: ReactPlayerProps = useMemo(() => {
    return {
      controls: !loopVideo,
      light: !loopVideo && poster,
      loop: loopVideo,
      url: source,
      playing: isPlaying,
      height: '100%',
      width: '100%',
      style: {
        borderRadius: '15px',
        overflow: 'hidden',
        backgroundColor: '#000000',
        aspectRatio: isFixedAspectRatio
          ? FIXED_ASPECT_RATIO_FOR_VIDEO_MODAL
          : undefined,
        ...styles,
      },
      playIcon: <PlayIcon isOverlay={isOverlay} />,
      onClickPreview: () => setIsPlaying(true),
      onPause: () => setIsPlaying(false),
      onPlay: () => setIsPlaying(true),
      muted,
      playsinline,
    };
  }, [
    source,
    loopVideo,
    poster,
    isPlaying,
    styles,
    muted,
    playsinline,
    isFixedAspectRatio,
    isOverlay,
  ]);

  return <ReactPlayer {...reactPlayerProps} />;
};

export default Video;
