'use client';

import { useCallback, useContext } from 'react';
import { ImageData } from '@/types';
import ImageBlock from '@/components/ImageBlock';
import { cn } from '@/lib/tailwind';
import { HomeHeroContext } from '../contexts/HomeHeroContext';

type Props = {
  image: ImageData;
};

const BackgroundImage = ({ image }: Props) => {
  const { setIsBackgroundImageLoaded } = useContext(HomeHeroContext);

  const onLoad = useCallback(() => {
    setIsBackgroundImageLoaded(true);
  }, [setIsBackgroundImageLoaded]);

  return (
    <ImageBlock
      image={image}
      loading="eager"
      fill
      className={cn(
        'object-cover',
        'z-[-1] brightness-[0.8]',
        'max-desktop-sm:scale-125 max-desktop-sm:-translate-y-12',
      )}
      onLoad={onLoad}
    />
  );
};

export default BackgroundImage;
