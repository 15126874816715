import React from 'react';
import { cn } from '@/lib/tailwind';
import ImageBlock from '@/components/ImageBlock';
import Text from '@/components/text';
import Icon from '@/external/icon';
import Conditional from '../Conditional';
import ClickAction from '../CallToAction/behaviour/ClickAction';
import TextLink from '../CallToAction/ui/TextLink';
import { Badge } from '../ui/badge';
import { LibraryCardData } from './types';

const LibraryCard = ({
  title,
  description,
  label,
  labelVariant,
  cta,
  image,
  isPlayIconEnabled = true,
}: LibraryCardData) => {
  const actionType = cta.navatticDemoConfig
    ? 'OPEN_NAVATTIC_DEMO'
    : cta.modalContent
    ? 'OPEN_MODAL'
    : 'NAVIGATE';
  return (
    <div
      className={cn(
        'p-[3px] h-full rounded-[8px] bg-whiteSmoke group hover:bg-mintyBlueGradient hover:cursor-pointer shadow-libraryCard',
        'w-[312px] tablet-sm:w-[330px] desktop-lg:w-[400px]',
      )}
    >
      <ClickAction
        as="a"
        {...cta}
        containerClassName="flex flex-col overflow-hidden rounded-[6px] bg-white h-full"
        action={actionType}
      >
        <div className="relative w-full h-[150px] tablet-sm:h-[180px] desktop-sm:h-[160px] desktop-lg:h-[200px]">
          <ImageBlock image={image} fill className="object-cover" />
          <Conditional renderIf={isPlayIconEnabled}>
            <div className="absolute h-full w-full bg-[#00000066]">
              <Icon
                icon="playRounded"
                className={cn(
                  'absolute h-[60px] w-[60px] desktop-lg:h-[88px] desktop-lg:w-[88px]',
                  'top-1/2 left-1/2 z-10 -translate-x-1/2 -translate-y-1/2',
                )}
              />
            </div>
          </Conditional>
        </div>
        <div className="flex-1 flex flex-col p-4 tablet-sm:p-6">
          <div className="flex-1">
            <Conditional renderIf={label}>
              <Badge variant={labelVariant} className="mb-4 desktop-sm:mb-5">
                {label}
              </Badge>
            </Conditional>
            <p className="text-mobile-sm/body-1-bold tablet-sm:text-tablet-sm/heading-6 desktop-sm:text-tablet-sm/heading-5  desktop-lg:text-desktop-large/heading-5">
              {title}
            </p>
            <Conditional renderIf={description}>
              <Text
                className={cn(
                  'block text-mobile-sm/body-3 tablet-sm:text-tablet-sm/body-4 desktop-sm:text-tablet-sm/body-3 desktop-lg:text-desktop-large/body-3',
                  'mt-2.5 tablet-sm:mt-4',
                )}
              >
                {description}
              </Text>
            </Conditional>
          </div>
          <TextLink
            {...cta}
            className="mt-6 tablet-sm:8 group-hover:text-primaryOceanBlue group-hover:underline"
          />
        </div>
      </ClickAction>
    </div>
  );
};

export default LibraryCard;
