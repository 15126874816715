import { FilterItem, FilterOption } from '@/components/subNavFilter/types';

interface CreateFilterMenuProps {
  newsFilterLabel: string;
  newsFilterOptions: Array<{ id: string; label: string }>;
  uniqueYears: Array<{ id: string; label: string }>;
  uniqueYearsLabel: string;
  selectedFilterOption: string;
  selectedUniqueYearsOption?: string;
  handleNewsFilterChange: (option: string) => void;
  handleUniqueYearsFilterChange: (option: string) => void;
}

export const createFilterMenu = ({
  newsFilterLabel,
  newsFilterOptions,
  uniqueYears,
  uniqueYearsLabel,
  selectedFilterOption,
  selectedUniqueYearsOption,
  handleNewsFilterChange,
  handleUniqueYearsFilterChange,
}: CreateFilterMenuProps): FilterItem[] => {
  const getSelectedLabel = (
    options: Array<FilterOption>,
    selectedId?: string,
  ) => {
    return (
      options.find(option => option.id === selectedId)?.label || selectedId
    );
  };

  return [
    {
      label: newsFilterLabel,
      options: newsFilterOptions,
      handleClick: handleNewsFilterChange,
      selectedOption: getSelectedLabel(newsFilterOptions, selectedFilterOption),
    },
    {
      label: uniqueYearsLabel,
      options: uniqueYears,
      handleClick: handleUniqueYearsFilterChange,
      selectedOption: getSelectedLabel(uniqueYears, selectedUniqueYearsOption),
    },
  ];
};
