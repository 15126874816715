import { useTranslations } from 'next-intl';
import React from 'react';

import Link from 'next/link';
import Icon from '@/external/icon';
import { cn } from '@/lib/tailwind';
import Conditional from '../Conditional';
import { NoResultsFallback } from './types';

const TITLE_TEXT = `Sorry! no result found :(`;
const SUBTITLE_TEXT = `Please try another way`;
const SEARCHED_QUERY_PLACEHOLDER = '<searched query>';

const NoResultsFallback = ({
  title,
  subTitle,
  recommendedLinks = [],
  queryString,
  className,
}: NoResultsFallback) => {
  const t = useTranslations();
  const baseTitle = title ?? t(TITLE_TEXT);
  const formattedTitle = queryString
    ? baseTitle.replace(SEARCHED_QUERY_PLACEHOLDER, `"${queryString}"`)
    : baseTitle;

  return (
    <div
      className={cn(
        'flex flex-col items-center justify-center max-tablet-sm:text-center',
        className,
      )}
    >
      <Icon
        icon="resultsNotFound"
        className="w-[90px] h-[76px] tablet-sm:w-[122px] tablet-sm:h-[102px]"
      />
      <div
        className={cn(
          'text-mobile-sm/heading-4 tablet-sm:text-tablet-sm/heading-3 desktop-lg:text-desktop-large/heading-4',
          'mt-8 tablet-sm:mt-10',
        )}
      >
        {formattedTitle}
      </div>
      <div className="text-mobile-sm/body-2 mt-4 tablet-sm:text-tablet-sm/body-2 desktop-lg:text-desktop-large/body-2">
        {subTitle ?? t(SUBTITLE_TEXT)}
      </div>
      <Conditional renderIf={Boolean(recommendedLinks.length)}>
        <div
          className={cn(
            'mt-8 tablet-sm:mt-10 grid tablet-sm:grid-cols-2 w-full',
            'gap-y-4 tablet-sm:gap-y-6 tablet-sm:gap-x-10 desktop-lg:gap-y-4 desktop-lg:gap-x-[52px]',
          )}
        >
          {recommendedLinks.map((link, index) => {
            return (
              <div
                key={index}
                className=" text-primaryOceanBlue text-tablet-sm/body-3 desktop-lg:text-desktop-large/body-2 hover:underline"
              >
                <Link href={link.url}>{link.label}</Link>
              </div>
            );
          })}
        </div>
      </Conditional>
    </div>
  );
};

export default NoResultsFallback;
