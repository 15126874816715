export const PRODUCT_SLUG_MAP = {
  'Sprinklr Service': 'customer-service',
  'Sprinklr Insights': 'consumer-intelligence',
  'Sprinklr Social': 'social-media-management',
  'Sprinklr Marketing': 'marketing-and-advertising',
  Platform: 'platform',
};
export const IMAGE_ROTATE_STYLES = {
  transform:
    'rotate3d(1, 1, 0, clamp(0deg, calc(30deg - (var(--scroll) - var(--scroll-delay))*var(--scroll-speed)),30deg))',
};

export const ACTION_OPEN_MODAL = 'OPEN_MODAL';
export const VIDEO_CONTENT_TYPE = 'video';
export const THUMBNAIL_IMAGE_TITLE = 'Thumbnail Title';
