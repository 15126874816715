import { cn } from '@/lib/tailwind';

type Props = {
  children: React.ReactNode;
  isLastRowTablet: boolean;
  isLastRowDesktop: boolean;
  isLastRowMobile: boolean;
};

export const GridWrapper = ({
  children,
  isLastRowTablet,
  isLastRowDesktop,
  isLastRowMobile,
}: Props) => {
  return (
    <div
      className={cn(
        'pb-6 tablet-sm:pb-10 desktop-lg:pb-12',
        'mt-8 tablet-sm:mt-10 desktop-lg:mt-12',
        'border-b border-lightGrey',
        isLastRowTablet && 'max-desktop-sm:border-0',
        isLastRowDesktop && 'desktop-sm:border-0',
        isLastRowTablet && !isLastRowMobile && 'max-tablet-sm:border-b',
      )}
    >
      {children}
    </div>
  );
};
