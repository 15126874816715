'use client';

import { ReactNode } from 'react';
import { useMeasure } from 'react-use';
import { useDirection } from '@radix-ui/react-direction';
import { cn } from '@/lib/tailwind';

const SLIDER_SPEED_IN_PX_PER_MS = 0.05;

const InfiniteScroller = ({
  children,
  containerClassname,
  trackClassname,
}: {
  children: ReactNode;
  containerClassname?: string;
  trackClassname?: string;
}) => {
  const [ref, { width }] = useMeasure<HTMLDivElement>();
  const writingDirection = useDirection();
  const animationDuration = Math.floor(width / SLIDER_SPEED_IN_PX_PER_MS);
  return (
    <div
      style={{
        ['--animation-duration' as string]: `${animationDuration}ms`,
        ['--slide-direction' as string]: writingDirection === 'rtl' ? 1 : -1,
      }}
      className={cn('overflow-hidden flex group', containerClassname)}
    >
      <div
        ref={ref}
        className={cn(
          'shrink-0 h-full animate-[slide_var(--animation-duration)_infinite_linear_none_running] group-hover:paused',
          trackClassname,
        )}
      >
        {children}
      </div>
      <div
        aria-hidden
        className={cn(
          'shrink-0 h-full animate-[slide_var(--animation-duration)_infinite_linear_none_running] group-hover:paused',
          trackClassname,
        )}
      >
        {children}
      </div>
    </div>
  );
};

export default InfiniteScroller;
